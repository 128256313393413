@import url('https://fonts.googleapis.com/css2?family=Smooch+Sans:wght@300;400;600&display=swap');

@mixin mobile {
  @media (max-width: 640px) {
    @content;
  }
}

@mixin darktheme() {
  color: white;
  background-color: black;
  .projects {
    background-color: rgba($color: #a1a1a1, $alpha: 0.45);
  }
  svg {
    filter: drop-shadow(10px 10px 5px white);
  }
}
