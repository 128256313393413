@import './global.scss';

body {
  margin: 0;
  font-family: 'Smooch Sans', sans-serif;
  fill: rgb(255, 230, 0);
  svg {
    filter: drop-shadow(10px 10px 5px black);
  }
}

.app {
  .toggle {
    cursor: pointer;
    position: absolute;
    z-index: 1;
    width: 500px;
    height: 500px;
    overflow: hidden;
    opacity: 0.6;
    transition: all 0.5s ease-in-out;
    &:hover {
      opacity: 0.8;
    }
    @include mobile {
      width: 100vw;
    }
  }
  a {
    color: white;
    text-decoration: none;
    font-weight: 600;
    text-shadow: 2px 2px 5px black;
    transition: all 0.5s ease-in-out;

    &:hover {
      text-shadow: 5px 5px 5px yellow;
    }
  }
  background-color: white;
  &.dark {
    @include darktheme;
  }
  video {
    position: absolute;
    width: 100%;
    object-fit: cover;
    opacity: 0.4;
    z-index: 0;
  }
  h2 {
    font-size: 4vmax;
    text-shadow: white 2px 2px 5px;
  }
  height: 100vh;
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
  overflow: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  > * {
    height: 100vh;
    scroll-snap-align: start;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
  }
}

@keyframes buzz {
  0% {
    top: 10vh;
  }
  10% {
    top: 0;
  }
  20% {
    top: 10vh;
  }
  30% {
    top: 0;
  }
  40% {
    top: 10vh;
  }
  50% {
    top: 0;
    transform: translateX(50vw) rotateY(180deg);
  }
  60% {
    top: 10vh;
  }
  70% {
    top: 0;
  }
  80% {
    top: 10vh;
  }
  90% {
    top: 0;
  }
  0% {
    top: 10vh;
  }
}
