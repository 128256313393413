@import './global.scss';
@import './anims.scss';
.projects {
  @include mobile {
    ul {
      flex-direction: column;
      li {
        a {
          font-size: 5vmin !important;
        }
      }
    }
  }
  width: 100%;
  justify-content: space-evenly !important;
  background-color: rgba($color: #000000, $alpha: 0.25);
  border-radius: 200px;
  > * {
    z-index: 1;
  }
  ul {
    width: 90vw;
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: space-around;
    align-items: center;
    li {
      display: flex;
      &:hover {
        .art {
          opacity: 1;
          box-shadow: 10px 10px 10px black;
        }
      }
      display: flex;
      flex-direction: column;
      align-items: center;
      .art {
        height: 20vh;
        opacity: 0.5;
        transition: all 0.2s ease-in-out;
      }
      a {
        font-size: 3vmin;
        text-decoration: none;
      }
    }
  }
}

.disabled {
  pointer-events: none;
  text-decoration: none;
  color: grey !important;
}
