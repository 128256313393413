@import './global.scss';
@import './anims.scss';
.home {
  flex-direction: row !important;
  justify-content: space-evenly;
  > * {
    z-index: 1;
  }
  @include mobile {
    flex-direction: column !important;
    .latest {
      width: 300px !important;
      height: 300px !important;
    }
    .imgContainer {
      .logo {
        width: 300px !important;
        height: 300px !important;
      }
    }
  }
  .imgContainer {
    display: flex;
    width: 40vw;
    align-items: center;
    justify-content: center;
    .logo {
      width: 500px;
      height: 500px;
      filter: drop-shadow(10px 10px 10px black);
    }
    .bee {
      position: absolute;
      left: 25vw;
      top: 10vh;
      animation: buzz 5s infinite alternate;
    }
  }
  .latest {
    width: 400px;
    height: 400px;
    box-shadow: 10px 10px 10px black;
    iframe {
      width: 100%;
      height: 100%;
    }
  }
}
