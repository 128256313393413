@import './global.scss';
@import './anims.scss';
.links {
  @include mobile {
    ul {
      flex-direction: column !important;
      align-items: center;
      li {
        a {
          font-size: 6vmin !important;
        }
        svg {
          height: 10vh !important;
          width: 10vh !important;
          &:hover {
            height: 15vh !important;
          }
        }
      }
    }
  }
  ul {
    width: 50vw;
    justify-content: space-between;
    display: flex;
    list-style: none;
    flex-direction: row;
    margin: 0;
    padding: 0;
    li {
      svg {
        height: 15vh;
        width: 15vh;
        fill: rgb(51, 51, 51);
      }
      a {
        font-size: 4vmin;
        display: flex;
        flex-direction: column !important;
        align-items: center;
        transition: all 0.2s ease-in-out;
        text-shadow: 5px 5px 5px yellow !important;
      }
    }
  }
  > * {
    z-index: 1;
  }
}
